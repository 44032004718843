<template>
  <v-container fluid>
    <v-card-title class="justify-center" style="z-index: 20; position: sticky">
      <v-sheet class="my-2">
        <v-row align="center" justify="center">
          <v-toolbar dense elevation="1" rounded="xl">
            <v-input style="color: #2196F3" v-text="`Imagem`"/>
            <v-btn
                icon
                :disabled="tamanho <= 360"
                @click="tamanho -= ratio * 100"
            >
              <v-icon>
                mdi-minus
              </v-icon>
            </v-btn>
            <v-btn
                icon
                @click="rotation -= 90"
            >
              <v-icon>
                mdi-rotate-left
              </v-icon>
            </v-btn>
            <v-btn
                icon
                @click="
                tamanho = tamanhoPadrao;
                $refs.draggableContainer.style.left = positions.initialX;
                $refs.draggableContainer.style.top = positions.initialY;
                rotation = 0;
              "
            >
              <v-icon>
                mdi-restore
              </v-icon>
            </v-btn>
            <v-btn
                icon
                @click="rotation += 90"
            >
              <v-icon>
                mdi-rotate-right
              </v-icon>
            </v-btn>
            <v-btn
                icon
                :disabled="tamanho >= windowWidth"
                @click="tamanho += ratio * 100"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn icon color="info" @click="downloadDocumentos">
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-row>
      </v-sheet>
    </v-card-title>
    <div
        :key="tamanhoPadrao"
        ref="draggableContainer"
        id="draggable-container"
        class="d-flex flex-column justify-space-between align-center"
        @mousedown="dragMouseDown"
        :style="`transform: translate(-50%) rotate(${rotation}deg);`"
    >
      <v-img
          :aspect-ratio="ratio"
          :width="tamanho"
          :height="tamanho / ratio"
          :src="image.src"
          @load="setPositionTamanhoRatio"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
                indeterminate
                color="grey darken-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ImageViewer",

  props: {
    file: {type: Object, required: true},
    fileURL: {type: String, required: true}
  },

  data() {
    return {
      pathImage: "data:image/" + this.file.tipo + ";base64,",
      image: new Image(),
      tamanho: 275,
      tamanhoPadrao: 275,
      ratio: 1.5,
      windowWidth: 0,
      windowHeight: 0,
      rotation: 0,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
        initialX: 0,
        initialY: 0
      }
    };
  },

  created() {
    this.windowHeight = window.innerHeight * 0.8;
    this.windowWidth = window.innerWidth * 0.8;
    this.image.src = this.pathImage + this.file.bytes;
  },

  methods: {
    downloadDocumentos() {
      let fileLink = document.createElement("a");
      fileLink.href = this.fileURL;
      fileLink.download = this.file.nome + "." + this.file.tipo;
      fileLink.click();
    },

    setPositionTamanhoRatio() {
      this.ratio = this.image.width / this.image.height;
      if (this.image.width >= this.windowWidth || this.image.height >= this.windowHeight) {
        if (this.image.width >= this.image.height) {
          this.tamanhoPadrao = this.tamanho = this.windowWidth / 1.5;
        } else {
          this.tamanhoPadrao = this.tamanho = this.windowHeight * this.ratio;
        }
      } else {
        this.tamanhoPadrao = this.tamanho = this.image.width;
      }
      this.positions.initialY = this.$refs.draggableContainer.style.top;
      this.positions.initialX = this.$refs.draggableContainer.style.left;
    },

    dragMouseDown(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
      this.$refs.draggableContainer.style.cursor = "grabbing";
    },

    elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
          this.$refs.draggableContainer.offsetTop -
          this.positions.movementY +
          "px";
      this.$refs.draggableContainer.style.left =
          this.$refs.draggableContainer.offsetLeft -
          this.positions.movementX +
          "px";
    },

    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.$refs.draggableContainer.style.cursor = "grab";
    }
  }
}
</script>

<style scoped>
#draggable-container {
  position: absolute;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  cursor: grab;
}
</style>