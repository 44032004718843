<template>
  <v-input
      :value="arquivosEscolhidos"
      :rules="ruleCampoObrigatorio ? [campoObrigatorioRule(arquivosEscolhidos)] : []"
      validate-on-blur
  >
    <input
        ref="uploader"
        class="d-none"
        type="file"
        :accept="acceptedFileFormats"
        @change="adicionaArquivo"
    />
    <div class="d-inline-block">
      <v-btn small color="primary" @click="$refs.uploader.click()">
        <v-icon left>
          mdi-upload
        </v-icon>
        Adicionar
      </v-btn>
      <v-sheet
          :outlined="arquivosEscolhidos.length > 0"
          rounded
          class="mt-2"
          :width="initialWidth"
          max-width="1080"
      >
        <v-chip-group column class="ml-2">
          <v-chip :key="index" v-for="(file, index) in arquivosEscolhidos">
            <span class="d-inline-block text-truncate">
              {{ file.name }}
            </span>
            <span class="ml-1">
              {{ tamanhoArquivo(file) }}
            </span>
            <v-btn class="ml-2" small icon @click.stop="removeArquivo(index)">
              <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
          </v-chip>
        </v-chip-group>
      </v-sheet>
      <span class="text-body-2">
        Total: {{ arquivosEscolhidos.length }} arquivo(s)
      </span>
    </div>
  </v-input>
</template>

<script>
import rules from "@/commons/rules";
import {mapActions} from "vuex";

export default {
  name: "FileInput",

  props: {
    acceptedFileFormats: {
      type: Array,
      required: false,
      default: () => [".jpeg", ".jpg", ".png", ".pdf"]
    },
    maxFileSize: {
      type: Number,
      required: true
    },
    numMaxFiles: {
      type: Number,
      required: true
    },
    ruleCampoObrigatorio: {
      type: Boolean,
      required: false,
      default: () => false
    },
    initialWidth: {
      type: String,
      required: false,
      default: () => '60vw'
    }
  },

  data() {
    return {
      arquivosEscolhidos: []
    };
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    isExtensionValid(filename) {
      return this.acceptedFileFormats.includes(
          filename.substring(filename.lastIndexOf(".")).toLowerCase()
      );
    },

    adicionaArquivo(selectedFile) {
      let file = selectedFile.target.files[0];

      if (this.isExtensionValid(file.name)) {
        if (!this.arquivosEscolhidos.map(a => a.name).includes(file.name)) {
          if (file.size !== 0 && file.size <= this.maxFileSize) {
            if (this.arquivosEscolhidos.length < this.numMaxFiles) {
              this.arquivosEscolhidos.push(file);
            } else {
              this.exibirAviso({
                mensagem: "Máximo de 20 arquivos atingido",
                tipo: "warning"
              });
            }
          } else {
            this.exibirAviso({
              mensagem: "Tamanho do arquivo inválido",
              tipo: "warning"
            });
          }
        } else {
          this.exibirAviso({
            mensagem: "Arquivo já adicionado",
            tipo: "warning"
          });
        }
      } else {
        this.exibirAviso({
          mensagem: "Formato do arquivo inválido",
          tipo: "warning"
        });
      }

      this.$refs.uploader.value = null;
    },

    removeArquivo(index) {
      this.arquivosEscolhidos.splice(index, 1);
    },

    tamanhoArquivo(file) {
      let tamanho = file.size / 1024;

      if (tamanho < 1024) {
        return "(" + tamanho.toPrecision(3) + " kB)";
      } else {
        return "(" + (tamanho / 1024).toPrecision(3) + " MB)";
      }
    }
  },

  watch: {
    arquivosEscolhidos: {
      handler() {
        this.$emit("input", this.arquivosEscolhidos);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.v-sheet--outlined {
  border-color: darkgray;
}
</style>
