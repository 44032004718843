<template>
  <v-container v-if="loadPdfErro">
    <v-row align="center" class="mt-10" justify="center">
      <v-alert type="error" outlined prominent colored-border border="left">
        Erro ao carregar PDF, tente novamente
      </v-alert>
    </v-row>
  </v-container>
  <v-container fluid v-else>
    <v-card-title class="justify-center" style="z-index: 20; position: sticky">
      <v-sheet class="my-2">
        <v-row align="center" justify="center">
          <v-toolbar
              dense
              elevation="1"
              rounded="xl"
              style="position: fixed; transform: translateY(25%);"
          >
            <v-input style="color: #2196F3" v-text="`PDF`"/>
            <v-btn text icon @click="page -= 1; scrollToPage('pdf'+file.nome+'Page'+page)" :disabled="page === 1">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="text-body-2">{{ page }}/{{ numPages }}</span>
            <v-btn text icon @click="page += 1; scrollToPage('pdf'+file.nome+'Page'+page)"
                   :disabled="page === numPages">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn icon @click="widthPdf -= 10; heightPdf -= 10" :disabled="widthPdf === 20">
              <v-icon>
                mdi-minus
              </v-icon>
            </v-btn>
            <v-btn text icon @click="rotate -= 90">
              <v-icon>mdi-rotate-left</v-icon>
            </v-btn>
            <v-btn
                icon
                @click="
                heightPdf = 100;
                widthPdf = 100;
                rotate = 0;
                page = 1;
                $refs.absoluteContainer.scrollTo(0,0);
                $refs.draggableContainer.style.left = positions.initialX;
                $refs.draggableContainer.style.top = positions.initialY;
              "
            >
              <v-icon>
                mdi-restore
              </v-icon>
            </v-btn>
            <v-btn text icon @click="rotate += 90">
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
            <v-btn icon @click="widthPdf += 10; heightPdf += 10" :disabled="widthPdf === 300">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn icon color="info" @click="download">
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-row>
      </v-sheet>
    </v-card-title>
    <div ref="absoluteContainer"
         :id="'absolute-container-'+file.nome"
         @scroll="handleScroll"
         :style="`width: ${windowWidth}px; height: ${windowHeight}px; position: absolute; overflow: scroll`">
      <div ref="draggableContainer"
           id="draggable-container"
           class="d-flex flex-column justify-space-between align-center"
           @mousedown="dragMouseDown"
           :style="`width: auto; height: auto`">
        <pdf
            v-for="i in numPages"
            :key="i"
            :id="'pdf'+file.nome+'Page'+i"
            :rotate="rotate"
            :src="pdfSrc"
            :page="i"
            :style="`width: ${widthPdf}%; height: ${heightPdf}%`"/>
      </div>
    </div>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf
  },

  props: {
    file: {type: Object, required: true},
    fileURL: {type: String, required: true}
  },

  created() {
    this.windowHeight = window.innerHeight * 0.7;
    this.windowWidth = window.innerWidth * 0.75;
    this.loadPDF();
  },

  mounted() {
    this.positions.initialY = this.$refs.draggableContainer.style.top;
    this.positions.initialX = this.$refs.draggableContainer.style.left;

    this.pdfSrc.promise.then(pdf => {
      this.numPages = pdf.numPages;
      this.loadPdfErro = false;
    }).catch(() => {
      this.loadPdfErro = true;
    });
  },

  data() {
    return {
      pdfSrc: null,
      numPages: null,
      rotate: 0,
      page: 1,
      widthPdf: 100,
      heightPdf: 100,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
        initialX: 0,
        initialY: 0
      },
      windowHeight: 0,
      windowWidth: 0,
      loadPdfErro: false
    };
  },

  methods: {
    handleScroll() {
      let paginaPdf = document.getElementById('pdf'+this.file.nome+'Page1');
      let tamanhoPagina = paginaPdf.clientHeight;

      let scrollContainer = document.getElementById('absolute-container-'+this.file.nome);
      let scrollPosition = scrollContainer.scrollTop - paginaPdf.offsetParent.offsetTop;

      let numPage = parseInt(scrollPosition/tamanhoPagina) + 1;

      if(numPage >= 1 && numPage <= this.numPages) {
        this.page = numPage;
      }
    },

    download() {
      let fileLink = document.createElement("a");
      fileLink.href = this.fileURL;
      fileLink.download = this.file.nome + ".pdf";
      fileLink.click();
    },

    loadPDF() {
      this.pdfSrc = pdf.createLoadingTask(this.fileURL);
    },

    scrollToPage(ref) {
      let elemento = document.getElementById(ref);
      let scrollPosition = elemento.offsetTop;
      let deslocamento = elemento.offsetParent.offsetTop;
      this.$refs.absoluteContainer.scrollTo(0, (scrollPosition + deslocamento));
    },

    dragMouseDown(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
      this.$refs.draggableContainer.style.cursor = "grabbing";
    },

    elementDrag(event) {
      this.handleScroll();
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
          this.$refs.draggableContainer.offsetTop -
          this.positions.movementY +
          "px";
      this.$refs.draggableContainer.style.left =
          this.$refs.draggableContainer.offsetLeft -
          this.positions.movementX +
          "px";
    },

    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.$refs.draggableContainer.style.cursor = "grab";
    }
  },

  errorCaptured(err) {
    if (err.message === "pdfRender.cancel() is undefined") {
      return false;
    }
  },

  watch: {
    "pdfSrc._transport": {
      handler() {
        this.loadPdfErro = !this.pdfSrc._transport;
      },
      deep: false
    }
  }
};
</script>

<style scoped>
#draggable-container {
  position: relative;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  cursor: grab;
}
</style>
