<template>
  <div>
    <Consulta
        ref="filtroConsulta"
        titulo="Gerência de Documentos de Inscrição"
        :parametros="paramsFiltro"
        :colunas="headers"
        :ordenacao-colunas="sortBy"
        :consulta-callback="consulta"
        item-key="oid"
    >
      <template v-slot:areaParametros>
        <v-row dense>
          <v-col cols="6">
            <v-autocomplete
                v-model="paramsFiltro.processoOid"
                :items="processos"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Processo *"
                outlined
                :rules="[campoObrigatorioRule]"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.nome"
                dense
                label="Nome"
                name="nomeInscricao"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.cpf"
                v-mask="'###########'"
                :counter="11"
                dense
                label="CPF"
                maxlength="11"
                name="cpfInscricao"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
                multiple
                v-model="paramsFiltro.situacoesInscricaoOid"
                :items="situacoesInscricao"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Situação Inscrição"
                outlined
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.docs="{ item }">
        <div>
        <v-tooltip top :open-delay="defaultDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="justify-center"
                icon
                color="accent"
                @click.stop="downloadDocumentosParaVisualizacao(item)"
            >
              <v-icon color="#F3B664">
                mdi-file-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Visualizar documentos</span>
        </v-tooltip>
        <v-tooltip top :open-delay="defaultDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="justify-center"
                icon
                color="info"
                @click.stop="downloadDocumentos(item)"
            >
              <v-icon color="#9FBB73">
                mdi-download
              </v-icon>
            </v-btn>
          </template>
          <span>Baixar documentos</span>
        </v-tooltip>
        <v-tooltip top :open-delay="defaultDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="justify-center"
                icon
                color="shade"
                @click.stop="abrirDialogDocumentos(item)"
            >
              <v-icon color="#EC8F5E">
                mdi-upload
              </v-icon>
            </v-btn>
          </template>
          <span>Inserir documentos</span>
        </v-tooltip>
        </div>
      </template>

    </Consulta>
    <v-dialog scrollable v-if="dialogInserirDocumentos" v-model="dialogInserirDocumentos" width="50%">
      <template>
        <v-card>
          <v-card-title class="primary justify-start white--text">
            Envio de Documentos
          </v-card-title>
          <div class="d-flex justify-center pa-3 mt-3" style="gap: 4em">
            <p style="color: #3d4441"><strong>CANDIDATO</strong>: {{inscricaoDocumento.nome}}</p>
            <p style="color: #3d4441"><strong>CPF</strong>: {{inscricaoDocumento.cpf}}</p>
          </div>
          <v-card-title class="tituloForm" v-if="documentosJaEnviados.length > 0">
            Documentos já enviados
          </v-card-title>
          <v-card-text class="text-body-1">
            <v-row
                dense
                v-for="documento in documentosJaEnviados"
                :key="documento.nome"
            >
              <v-col>
                {{ documento.nome }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title class="tituloForm">
            Envio de documentos
          </v-card-title>
          <v-card-text>
            <p class="text-justify">
              Por favor, escolha os arquivos para fazer o upload. Os arquivos podem
              ser nos formatos <b>pdf, jpeg, jpg ou png</b> e você pode fazer upload de até
              <b>20 arquivos com o tamanho máximo de 10mb cada</b>.
              <br/>
              <br/>
              <b>
                IMPORTANTE! O envio de novos documentos com o mesmo nome que os antigos irá sobrescreve-los.
              </b>
            </p>
            <file-input
                v-model="arquivosEscolhidos"
                :max-file-size="10485760"
                :num-max-files="20"
                :ruleCampoObrigatorio="true"
                :initial-width="'40vw'"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="uploadDocumentos(inscricaoDocumento)" class="mr-4" color="primary">Confirmar</v-btn>
            <v-btn @click="dialogInserirDocumentos=false" color="error">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog scrollable v-if="dialogDocuments" v-model="dialogDocuments" width="80%">
      <v-card>
        <v-card-title class="primary justify-start white--text">
          Documentos
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="dialogDocuments = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-tabs v-model="documentosTab" show-arrows>
          <v-tab v-for="arquivo in arquivosVisualizacao" :key="arquivo.nome">
            <span class="d-inline-block text-truncate"> {{ arquivo.nome }} </span>
            <v-chip v-if="!!arquivo.faseEnvio"
                    text-color="white"
                    color="info"
                    class="ml-1"
                    small>
              <span class="text-truncate">
                  {{ arquivo.faseEnvio }}
              </span>
            </v-chip>
          </v-tab>
        </v-tabs>
        <v-card-text style="overflow: hidden">
          <v-tabs-items v-model="documentosTab">
            <v-tab-item
                v-for="arquivo in arquivosVisualizacao"
                :key="arquivo.nome"
            >
              <DocumentsViewer :file="arquivo"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import rules from "@/commons/rules";
import inscricaoService from "@/services/inscricao.service";
import processoService from "@/services/processo.service";
import situacaoInscricaoService from "@/services/situacaoInscricao.service";
import documentosService from "@/services/documentos.service";
import {mapActions} from "vuex";
import DocumentsViewer from "@/components/base/DocumentsViewer";
import Consulta from "@/components/pages/admin/consultas/Consulta";
import FileInput from "@/components/base/FileInput.vue";

export default {
  name: "Inscricao",
  components: {FileInput, Consulta, DocumentsViewer},

  data() {
    return {
      tabDocumentacao: 0,
      dialog: false,
      headers: [
        {text: "Nome", value: "nome"},
        {text: "CPF", value: "cpf"},
        {text: "Processo", value: "nomeProcesso"},
        {text: "Situação Inscrição", value: "situacaoInscricao"},
        {text: "Ações", value: "docs", sortable: false, align: "center", transient: true}
      ],
      defaultDelay: 1000,
      elementos: [],
      loading: false,
      options: {},
      numTotalElementos: 0,
      sortBy: ["nome"],
      paramsFiltro: {},
      processos: [],
      situacoesInscricao: [],
      inscricaoEditada: {},
      arquivosVisualizacao: [],
      dialogDocuments: false,
      documentosTab: 0,
      auditoriaDialog: false,
      inscricaoAuditoria: null,
      entidadeOid: null,
      notasInscricaoSelecionada: {},
      dialogInserirDocumentos: false,
      inscricaoDocumento: {},
      arquivosEscolhidos: [],
      consulta: inscricaoService.recuperarFiltro,
      documentosJaEnviados: []
    };
  },

  async created() {
    await processoService
        .recuperarProcessosComEnvioDocumentos()
        .then(response => {
          this.processos = response.data;
        })
        .catch(() => {
        });

    await situacaoInscricaoService
        .recuperarSituacoesInscricao()
        .then(response => {
          this.situacoesInscricao = response.data;
        });
  },

  async mounted() {
    if (this.$route.params.processoOid) {
      this.paramsFiltro.processoOid = this.$route.params.processoOid;

      this.$refs.filtroConsulta.mostrarResultado = true;

      await this.$refs.filtroConsulta.consultar();
    }
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    downloadDocumentos(inscricao) {
      documentosService.downloadDocumentosCandidato(inscricao.oid).then(response => {
        const file = new Blob([response.data]);
        const fileURL = URL.createObjectURL(file);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = inscricao.cpf + ".zip";
        fileLink.click();
      }).catch(async error => {
        let mensagem = "Não foi possível realizar download";
        if (error.response.status === 409) {
          await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = function () {
              resolve(JSON.parse(reader.result.toString()));
            };
            reader.readAsText(error.response.data);
          }).then(erro => {
            mensagem = erro.msg;
          }).catch();
        }
        this.exibirAviso({
          mensagem: mensagem,
          tipo: "error"
        });
      });
    },

    async uploadDocumentos(item) {
      if (this.arquivosEscolhidos.length > 0) {
        let loader = this.$loading.show();
        await documentosService.uploadDocumentosCandidato(this.arquivosEscolhidos, item).then(async () => {
          await documentosService.recuperaDocumentosInfoPorInscricao(item.oid).then(response => {
            this.documentosJaEnviados = response.data;
          })
          this.exibirAviso({
            mensagem: "Arquivos enviados com sucesso!",
            tipo: "success"
          });
        })
            .catch(() => {
              this.exibirAviso({
                mensagem: "Ocorreu um erro ao enviar os arquivos",
                tipo: "error"
              });
            });

        loader.hide();
      } else {
        this.exibirAviso({
          mensagem: "Você não adicionou nenhum arquivo.",
          tipo: "info"
        });
      }
    },

    async abrirDialogDocumentos(item) {
      let loader = this.$loading.show();
      await documentosService.recuperaDocumentosInfoPorInscricao(item.oid).then(response => {
        this.documentosJaEnviados = response.data;
      })
      this.inscricaoDocumento = item;
      this.dialogInserirDocumentos = !this.dialogInserirDocumentos;
      loader.hide();
    },

    async salvarAlteracaoSituacaoInscricao() {
      if (this.$refs.alterarSituacaoForm.validate()) {
        let loader = this.$loading.show();
        await inscricaoService
            .alterarSituacaoInscricao(
                this.inscricaoEditada.oid,
                this.novaSituacaoInscricaoOid
            )
            .then(() => {
              this.exibirAviso({
                mensagem: "Situação da inscrição atualizada com sucesso!",
                tipo: "success"
              });
            })
            .catch(() => {
              this.exibirAviso({
                mensagem: "Inscrição não encontrada",
                tipo: "error"
              });
            });

        await this.$refs.filtroConsulta.getDataFromApi();

        this.cancelarAlteracaoSituacaoInscricao();
        loader.hide();
      }
    },

    async downloadDocumentosParaVisualizacao(inscricao) {
      let loader = this.$loading.show();
      await documentosService
          .downloadDocumentosCandidatoParaVisualizacao(inscricao.oid)
          .then(response => {
            this.arquivosVisualizacao = response.data;
            this.dialogDocuments = true;
          })
          .catch(error => {
            let mensagem = "Não foi possível realizar download";
            if (error.response.status === 409) {
              mensagem = error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
          });
      loader.hide();
    },

  }
};
</script>

<style scoped></style>
