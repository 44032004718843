<template ref="documentsViewer">
  <v-sheet v-if="file.tipo === 'pdf'" :min-height="windowHeight">
    <PDFViewer :file="file" :fileURL="getFileURL"/>
  </v-sheet>
  <v-sheet v-else :min-height="windowHeight">
    <ImageViewer :file="file" :fileURL="getFileURL"/>
  </v-sheet>
</template>

<script>
import PDFViewer from "@/components/base/PDFViewer";
import ImageViewer from "@/components/base/ImageViewer";

export default {
  name: "DocumentsViewer",

  components: {PDFViewer, ImageViewer},

  props: {
    file: {type: Object, required: true}
  },

  created() {
    this.windowHeight = window.innerHeight * 0.78;
    this.windowWidth = window.innerWidth * 0.78;
  },

  methods: {
    b64toBytes(b64Data, sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return byteArrays;
    }
  },

  computed: {
    getFileURL() {
      let bytes = this.b64toBytes(this.file.bytes);
      let blob = new window.Blob(bytes, {
        type:
            this.file.tipo === "pdf"
                ? "application/" + this.file.tipo
                : "data/" + this.file.tipo
      });
      return URL.createObjectURL(blob);
    }
  }
};
</script>

<style scoped/>
